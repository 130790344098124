(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/var/www/html/erp.aero/lgm-store/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/var/www/html/erp.aero/lgm-store/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_2App from "/var/www/html/erp.aero/lgm-store/modules/contact-us-widget/ui/src/index.js";


import index_3App from "/var/www/html/erp.aero/lgm-store/modules/catalog-widget/ui/src/index.js";


import index_4App from "/var/www/html/erp.aero/lgm-store/modules/catalog-filters-widget/ui/src/index.js";


import index_5App from "/var/www/html/erp.aero/lgm-store/modules/asset/ui/src/index.js";


import index_6App from "/var/www/html/erp.aero/lgm-store/modules/generator-widget/ui/src/index.js";


import index_7App from "/var/www/html/erp.aero/lgm-store/modules/signin-widget/ui/src/index.js";


import index_8App from "/var/www/html/erp.aero/lgm-store/modules/signup-widget/ui/src/index.js";


import index_9App from "/var/www/html/erp.aero/lgm-store/modules/password-recovery-widget/ui/src/index.js";


import index_10App from "/var/www/html/erp.aero/lgm-store/modules/pdfmaker-widget/ui/src/index.js";


import index_11App from "/var/www/html/erp.aero/lgm-store/modules/checkout-widget/ui/src/index.js";


import index_12App from "/var/www/html/erp.aero/lgm-store/modules/payment-widget/ui/src/index.js";


import index_13App from "/var/www/html/erp.aero/lgm-store/modules/product-details-widget/ui/src/index.js";


import index_14App from "/var/www/html/erp.aero/lgm-store/modules/add-to-cart-widget/ui/src/index.js";


  
import index_0Stylesheet from "/var/www/html/erp.aero/lgm-store/modules/asset/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();
index_9App();
index_10App();
index_11App();
index_12App();
index_13App();
index_14App();